import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: h => h('i', { class: 'icon-cross' }),
  },
  OpenIndicator: {
    render: h => h('i', {
      class: 'icon-chevron-down'
    }),
  },
})

Vue.component('v-select', vSelect)
