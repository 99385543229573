const Config = {
  app:{
    webaname:'ONEDA',
    company:'ONEDA',
    copyright:()=>{
      let date = new Date()
      if(date.getFullYear()>2022){
        return '2022-'+date.getFullYear()
      }else{
        return '2022'
      }
    }
  },

  encrypt_key: "#@PI2022!!",
  datePickerOnlyDate: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
  mr:{
    tipeAkta: [{
        text: 'Akta Pendirian',
        value: 'Akta Pendirian'
      },
      {
        text: 'Akta Penyesuaian',
        value: 'Akta Penyesuaian'
      },
      {
        text: 'Akta Perubahan Terakhir',
        value: 'Akta Perubahan Terakhir'
      }
    ],
    returnReason: [
      {
        text: 'Dokumen Legalitas Tidak Lengkap',
        value: 'Dokumen Legalitas Tidak Lengkap'
      },
      {
        text: 'Dokumen Persyaratan Pencairan Tidak Lengkap',
        value: 'Dokumen Persyaratan Pencairan Tidak Lengkap'
      },
      {
        text: 'Dokumen Pendukung Lainnya Tidak Lengkap',
        value: 'Dokumen Pendukung Lainnya Tidak Lengkap'
      },
      {
        text: 'Butuh Penyelesaian Memo Penyimpangan',
        value: 'Butuh Penyelesaian Memo Penyimpangan'
      },
      {
        text: 'Data Debitur Tidak Lengkap/Salah',
        value: 'Data Debitur Tidak Lengkap/Salah'
      },
      {
        text: 'Kasus Khusus (harap input keterangan di remark)',
        value: 'Kasus Khusus (harap input keterangan di remark)'
      },
    ],
    Month: [{
        text: 'Januari',
        value: '01'
      },
      {
        text: 'Februari',
        value: '02'
      },
      {
        text: 'Maret',
        value: '03'
      },
      {
        text: 'April',
        value: '04'
      },
      {
        text: 'Mei',
        value: '05'
      },
      {
        text: 'Juni',
        value: '06'
      },
      {
        text: 'Juli',
        value: '07'
      },
      {
        text: 'Agustus',
        value: '08'
      },
      {
        text: 'September',
        value: '09'
      },
      {
        text: 'Oktober',
        value: '10'
      },
      {
        text: 'November',
        value: '11'
      },
      {
        text: 'Desember',
        value: '12'
      }
    ],
    TipeLokasi: [
    {
      text: 'Kantor',
      value: 'Kantor'
    },
    {
      text: 'Rumah',
      value: 'Rumah'
    },
    {
      text: 'Gudang',
      value: 'Gudang'
    },
    {
      text: 'Pabrik',
      value: 'Pabrik'
    },
    {
      text: 'Lainnya',
      value: 'Lainnya'
    }
    ],
    StatusOptions: [
      { text: 'Aktif', value: 'Y' },
      { text: 'Nonaktif', value: 'N' },
    ],
    JenisDebitur: [{
      text: 'Debitur Individu',
      value: 'INDIVIDU'
    }, {
      text: 'Debitur Perusahaan',
      value: 'CORPORATE'
    }
    ],
    JenisLaporan: [{
      text: 'Visit',
      value: 'V'
    }, {
      text: 'Survey',
      value: 'S'
    } 
    ],
    StatusLaporan: [
      {
        text: 'Completed',
        value: 'C'
      }, {
        text: 'Incomplete',
        value: 'I'
      },
    ],
    mrJenisPenjamin: [
      {
        text: 'Individu',
        value: 'INDIVIDU'
      },
      {
        text: 'Corporate',
        value: 'CORPORATE'
      }
    ],
    mrSapaan: [{
        text: 'Bpk',
        value: 'Bpk'
      },
      {
        text: 'Ibu',
        value: 'Ibu'
      }
    ],
    IdenPengurus: [
      {
        text: 'KTP',
        value: 'KTP'
      },
      {
        text: 'Paspor',
        value: 'Paspor'
      },
      {
        text: 'NPWP',
        value: 'NPWP'
      },
      {
        text: 'Lainnya',
        value: 'Lainnya'
      }
    ],
    StatusDeb: [{
        text: 'Warm',
        value: 'W'
      },
      {
        text: 'Hot',
        value: 'H'
      },
      {
        text: 'Active',
        value: 'A'
      }
    ],
    BucketSusunanType: [
      {
        text: 'SERIAL',
        value: 'SERIAL'
      },
      {
        text: 'PARAREL',
        value: 'PARAREL'
      }
    ],
    StatusDebType:[
      {
        text: 'PT',
        value: 'PT'
      },
      {
        text: 'CV',
        value: 'CV'
      }
    ],
    ColateralFields: [{
        text: 'Text',
        value: 'text'
      },
      {
        text: 'Long Text',
        value: 'long-text'
      },
      {
        text: 'Radio Option',
        value: 'radio'
      },
      {
        text: 'Select Option',
        value: 'select'
      },
      {
        text: 'Checkbox',
        value: 'checkbox'
      },
      {
        text: 'File',
        value: 'file'
      }
    ],
    CollateralUsed: [{
        text: 'Debitur Perusahaan',
        value: 'CORPORATE'
      },
      {
        text: 'Debitur Individu',
        value: 'INDIVIDU'
      },
    ],
    FinancingOpt: [{
        text: 'Ya',
        value: 'Y'
      },
      {
        text: 'Tidak',
        value: 'N'
      },
    ],
    AbleOptions: [
      { text: 'Enable', value: 'Y' },
      { text: 'Disable', value: 'N' },
    ],
    mrCatDocType: [
      { text: 'Pembiayaan', value: 'LO' },
      { text: 'Legalitas', value: 'LE' },
    ],
    weddingStatus: [
      { text: "Kawin", value: '1' },
      { text: "Belum Kawin", value: '2' },
      { text: "Cerai", value: '3' }
    ],
    incomeSrcStatus: [
      { text: "Gaji", value: '1' },
      { text: "Usaha", value: '2' },
      { text: "Lainnya", value: '3' }
    ],
  },
  wording:{
    detectUpdate: 'Perubahan data belum di simpan.'
  },
  toolsBasicCK: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo'
  ],
  dataShownOptions: [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '30', value: 30 },
  ],
  dataShown:10
}
export default Config