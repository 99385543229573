const bo_routes = [
  {
    path: '',
    redirect: { name: 'BoLogin' },
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/backend/BoLogin.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },
  {
    path: 'dashboard/:pageSlug?',
    name: 'BoDashboard',
    component: () => import('@/views/backend/BoDashboard.vue'),
    meta: {
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'account-setting',
    name: 'BoAccountSetting',
    component: () => import('@/views/backend/BoAccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'fasilitas-inquiry/:pageSlug?/:pageId?',
    name: 'BoLoan',
    component: () => import('@/views/backend/BoLoan/List.vue'),
    meta: {
      menu: 'BoLoan',
      pageTitle: 'Fasilitas Inquiry',
      objName: 'Fasilitas',
      hasCta: 'true',
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/BoUser/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'BoUserLevel',
    component: () => import('@/views/backend/BoUserLevel/List.vue'),
    meta: {
      menu: 'BoUserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'audit-trail/:pageSlug?',
    name: 'BoAuditTrail',
    component: () => import('@/views/backend/BoAuditTrail/AuditTrail.vue'),
    meta: {
      menu: 'BoAuditTrail',
      pageTitle: 'Audit Trail',
      objName: 'Audit Trail'
    },
  },
  {
    path: 'master/tipe-dokumen/:pageSlug?',
    name: 'BoMDocType',
    component: () => import('@/views/backend/BoMDocType/List.vue'),
    meta: {
      menu: 'BoMDocType',
      hasCta: 'true',
      pageTitle: 'Master Tipe Dokumen',
      objName: 'Master Tipe Dokumen'
    },
  },
  {
    path: 'master/bucket-flow/:pageSlug?',
    name: 'BoMBucketFlow',
    component: () => import('@/views/backend/BoMBucketFlow/List.vue'),
    meta: {
      menu: 'BoMBucketFlow',
      hasCta: 'true',
      pageTitle: 'Bucket Flow',
      objName: 'Bucket Flow'
    },
  },
  {
    path: 'master-department/:pageSlug?',
    name: 'BoMDepartment',
    component: () => import('@/views/backend/BoMDepartment/List.vue'),
    meta: {
      menu: "BoMDepartment",
      pageTitle: 'Master Departemen',
      hasCta: 'true',
      objName: 'Master Departemen'
    },
  },
  {
    path: 'master-collateral/:pageSlug?',
    name: 'BoMColateral',
    component: () => import('@/views/backend/BoMColateral/List.vue'),
    meta: {
      menu: "BoMColateral",
      pageTitle: 'Master Collateral',
      hasCta: 'true',
      objName: 'Master Collateral'
    },
  },
  {
    path: 'master-fasilitas-pembiayaan/:pageSlug?',
    name: 'BoFasilitas',
    component: () => import('@/views/backend/BoFasilitas/List.vue'),
    meta: {
      menu: "BoFasilitas",
      pageTitle: 'Master Pembiayaan',
      hasCta: 'true',
      objName: 'Master Pembiayaan'
    },
  },
  {
    path: 'master-alasan-fasilitas-drop/:pageSlug?',
    name: 'BoMFasilitasDrop',
    component: () => import('@/views/backend/BoMFasilitasDrop/List.vue'),
    meta: {
      menu: "BoMFasilitasDrop",
      pageTitle: 'Master Pembiayaan Drop',
      hasCta: 'true',
      objName: 'Master Pembiayaan Drop'
    },
  },
  {
    path: 'master-golongan-debitur/:pageSlug?',
    name: 'BoMGolDebitur',
    component: () => import('@/views/backend/BoMGolDebitur/List.vue'),
    meta: {
      menu: "BoMGolDebitur",
      pageTitle: 'Master Golongan Debitur',
      hasCta: 'true',
      objName: 'Master Golongan Debitur'
    },
  },
  {
    path: 'master-bidang-usaha/:pageSlug?',
    name: 'BoMBidangUsaha',
    component: () => import('@/views/backend/BoMBidangUsaha/List.vue'),
    meta: {
      menu: "BoMBidangUsaha",
      pageTitle: 'Master Bidang Usaha',
      hasCta: 'true',
      objName: 'Master Bidang Usaha'
    },
  },
  {
    path: 'master-bentuk-bidang-usaha/:pageSlug?',
    name: 'BoMKodeUsaha',
    component: () => import('@/views/backend/BoMKodeUsaha/List.vue'),
    meta: {
      menu: "BoMKodeUsaha",
      pageTitle: 'Master Bentuk Bidang Usaha',
      hasCta: 'true',
      objName: 'Master Bentuk Bidang Usaha'
    },
  },
  {
    path: 'master-jenis-usaha/:pageSlug?',
    name: 'BoMJenisUsaha',
    component: () => import('@/views/backend/BoMJenisUsaha/List.vue'),
    meta: {
      menu: "BoMJenisUsaha",
      pageTitle: 'Master Jenis Usaha',
      hasCta: 'true',
      objName: 'Master Jenis Usaha'
    },
  },
  {
    path: 'debitur-perusahaan/:pageSlug?',
    name: 'BoDebiturPerusahaan',
    component: () => import('@/views/backend/BoDebiturPerusahaan/List.vue'),
    meta: {
      menu: "BoDebiturPerusahaan",
      pageTitle: 'Debitur Perusahaan',
      hasCta: 'true',
      objName: 'Debitur Perusahaan'
    },
  },
  {
    path: 'debitur-individual/:pageSlug?',
    name: 'BoDebiturIndividual',
    component: () => import('@/views/backend/BoDebiturIndividual/List.vue'),
    meta: {
      menu: "BoDebiturIndividual",
      pageTitle: 'Debitur Individual',
      hasCta: 'true',
      objName: 'Debitur Individual'
    },
  },
  {
    path: 'laporan-kunjungan/:pageSlug?',
    name: 'BoLaporanKunjungan',
    component: () => import('@/views/backend/BoLaporanKunjungan/List.vue'),
    meta: {
      menu: "BoLaporanKunjungan",
      pageTitle: 'Survey / Visit',
      hasCta: 'true',
      objName: 'Survey / Visit'
    },
  },
  {
    path: 'prospect-from-portal/:pageSlug?',
    name: 'BoProspect',
    component: () => import('@/views/backend/BoProspect/List.vue'),
    meta: {
      menu: "BoProspect",
      pageTitle: 'Prospect From Portal',
      objName: 'Prospect From Portal'
    },
  },
  {
    path: 'master-target-pencairan/:pageSlug?',
    name: 'BoMTargetPencairan',
    component: () => import('@/views/backend/BoMTargetPencairan/List.vue'),
    meta: {
      menu: "BoMTargetPencairan",
      pageTitle: 'Master Target Pencairan Bulanan',
      hasCta: 'true',
      objName: 'Master Target Pencairan Bulanan'
    },
  },
  {
    path: 'master-pendidikan/:pageSlug?',
    name: 'BoMPendidikan',
    component: () => import('@/views/backend/BoMPendidikan/List.vue'),
    meta: {
      menu: "BoMPendidikan",
      pageTitle: 'Master Pendidikan',
      hasCta: 'true',
      objName: 'Master Pendidikan'
    },
  },
  {
     path: 'master-notifikasi/:pageSlug?',
     name: 'BoMNotification',
     component: () => import('@/views/backend/BoMNotification/List.vue'),
     meta: {
       menu: "BoMNotification",
       pageTitle: 'Master Notifikasi',
       objName: 'Master Notifikasi'
     },
  },
  {
    path: 'report/:pageSlug?',
    name: 'BoReport',
    component: () => import('@/views/backend/BoReport/List.vue'),
    meta: {
      menu: "BoReport",
      pageTitle: 'Report List',
      objName: 'Report List'
    },
  },
  {
    path: 'notifikasi',
    name: 'Notification',
    component: () => import('@/views/backend/BoNotification/List.vue'),
    meta: {
      menu: "Notification",
      pageTitle: 'Notifikasi',
      objName: 'Notifikasi'
    },
  },
  {
    path: 'master-email-template/:pageSlug?',
    name: 'BoMEmailTemplate',
    component: () => import('@/views/backend/BoMEmailTemplate/List.vue'),
    meta: {
      menu: "BoMEmailTemplate",
      pageTitle: 'Master Email Template',
      objName: 'Master Email Template'
    },
  },
]
export default bo_routes